<template>
  <v-col>
    <v-row v-if="message.nextMessageHourApart" justify="center">
      <v-col cols="auto">
        {{ message.sentDate.toLocaleString() }}
      </v-col>
    </v-row>
    <v-row :justify="(message.userId === user.userId ? 'end' : 'start')">
      <v-col :cols="cols">
        <v-tooltip top :disabled="message.nextMessageHourApart">
          <template v-slot:activator="{ on, attrs}">
            <v-card rounded="xl" :color="messageColor" :dark="isMessageDark" flat v-bind="attrs" v-on="on">
              <v-card-subtitle>
                <b>{{ username }}</b>
              </v-card-subtitle>
              <v-card-text class="text-body-2">
                {{ text }}
              </v-card-text>
            </v-card>
          </template>
          <span>{{ message.sentDate.toLocaleString() }}</span>
        </v-tooltip>  
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import {auth} from '../../sharedPlugin'

export default {
  props: ['message', 'cols'],
  computed: {
    ...auth.mapComputed(['user']),
    
    messageColor() {
      return this.message.userId === this.user.userId ? 'primary' :  'light';
    },
    isMessageDark() {
      return this.message.userId === this.user.userId;
    },
    text() {
      return this.message.message
    },
    username() {
      return this.user.userId === this.message.userId ? 'You' : this.message.username;
    }
  }
}
</script>

<style scoped>
  .theme--light.v-card  {
    background-color: lightgrey;
  }
  .theme--light.v-card > .v-card__text, .theme--light.v-card .v-card__subtitle {
    color: black;
  }
  .theme--dark.v-card > .v-card__text, .theme--dark.v-card .v-card__subtitle {
    color: white;
  }
</style>
<template>
 <v-form @submit.prevent="sendMessage">
    <v-text-field
      label="Send message..."
      v-model="message"
      outlined
      :loading="sendingMessage"
      :disabled="!selectedConversation || sendingMessage"
      hide-details
    >
      <v-btn
        slot="append"
        icon
        @click="sendMessage"
      >
        <v-icon>mdi-send</v-icon>
      </v-btn>
    </v-text-field>
 </v-form>
</template>
<script>
import { messages } from '../../sharedPlugin'
export default {
  data: () => ({
    message: ''
  }),
  computed: {
    ...messages.mapComputed(['sendingMessage', 'selectedConversation'])
  },
  methods: {
    ...messages.mapMethods(['addMessageToConversation']),
    sendMessage() {
      if(this.message.length > 0) {
        this.addMessageToConversation(this.message).then(() => {
          this.message = '';
        })
      }
    },
  }
}
</script>
<style scoped>
.v-form ::v-deep .v-text-field--enclosed .v-input__append-inner {
  margin-top: 10px;
}

</style>
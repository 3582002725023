<template>
  <v-container ref="messageContainer" style="height: 100%" class="overflow-y-auto">
    <loading-circle v-if="loading"/>
    <v-row
      v-else
      v-for="(message, index) in newMessages" 
      :key="index"
      ref="messageComponents"
    >
      <Message :message="message" :cols="cols" />
    </v-row>
  </v-container>
</template>
<script>
import { messages, auth } from '../../sharedPlugin';
import LoadingCircle from '../LoadingCircle.vue';
import Message from './Message.vue';

export default {
  components: { Message, LoadingCircle },
  props: {
    cols: {
      type: String,
      default: null
    }
  },
  computed: {
    ...messages.mapComputed(['selectedConversation', 'messages', 'currentConversation', 'loading']),
    ...auth.mapComputed(['user']),
    newMessages() {
      let newMsgs = [...this.messages].sort((a, b) => a.sentDate - b.sentDate);
      let previousMsg = newMsgs[0] = {...newMsgs[0], nextMessageHourApart: true};
      
      for(let i = 1; i < newMsgs.length; i++) {
        const oneHour =  1000 * 60 * 60; 
        let timeDifference = newMsgs[i].sentDate.getTime() - previousMsg.sentDate.getTime();
        let currentMsg = {
          ...newMsgs[i],
          nextMessageHourApart: timeDifference > oneHour
        };

        newMsgs[i] = {...currentMsg};
        previousMsg = currentMsg;
      }
      return newMsgs;
    }
  },
  methods: {
    scrollToBottom() {
      if(this.$refs.messageComponents) {
        const lastComponent = this.$refs.messageComponents[this.$refs.messageComponents.length - 1];

        if(lastComponent) {
          this.$vuetify.goTo(
            lastComponent, 
            {
              container: this.$refs.messageContainer,
              duration: 0,
            })
        }
      }
    }
  },
  updated() {
    this.$nextTick(() => this.scrollToBottom())
  },
  // watch: {
  //   newMessages(val) {
  //     if(val.length > 0) {
  //       // this.$nextTick is not powerful enough... 'double requestAnimationFrame' hack to the resque!
  //       // see https://github.com/vuejs/vue/issues/9200
  //       window.requestAnimationFrame(() => 
  //         window.requestAnimationFrame(() => 
  //           this.$nextTick(this.scrollToBottom)
  //         )
  //       )
  //     }
  //   }
  // }
}
</script>
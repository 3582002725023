<template>
  <v-card flat class="justify-space-between" height="100%">
    <v-card-title class="pa-0 pb-6">
      <v-toolbar dark flat color="primary">
      <v-row align="center">
        <v-col class="text-left">
          <v-btn icon @click.stop="$emit('newMessageForm')">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </v-col>
        <v-spacer/>
        <v-col>
          <v-toolbar-title class="text-center">New Message</v-toolbar-title>
        </v-col>
        <v-spacer/>
        <v-col/>
      </v-row>
    </v-toolbar>
    </v-card-title>
    <v-card-text>
      <v-form @submit.prevent>
        <v-text-field
          label="Subject"
          auto-grow
          v-model="subject"
          outlined
          dense
        ></v-text-field>
        <v-autocomplete
          :loading="messageGroupsLoading"
          :label="user.role === 'patient' ? 'Group' : 'Patient'"
          v-model="group"
          :items="messageGroups"
          item-text="name" 
          item-value="id"
          outlined
          dense
        ></v-autocomplete>

        <v-textarea
          label="Message"
          auto-grow
          v-model="message"
          outlined
          dense
        ></v-textarea>
        <v-alert type="error" v-if="error">
          {{ error }}
        </v-alert>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn 
        color="primary" 
        outlined
        @click="discard"
      >
        Discard
      </v-btn>
      <v-spacer />
      <v-btn 
        color="primary"
        @click="createConversation" 
        :loading="loading"
      >
      Send
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {messages, auth} from '../../sharedPlugin'

export default {
  data: () => ({
    error: null
  }),
  computed: {
    ...auth.mapComputed(['user']),
    ...messages.mapComputed(['messageGroups', 'messageGroupsLoading', 'messageTo', 'loading']),
    subject: {
      get() {
        return this.messageTo.subject
      },
      set(val) {
        this.setMessageTo({...this.messageTo, subject: val})
        this.error = null;
      }
    },
    group: {
      get(){
        return this.messageGroups.find(el => this.user.role === 'patient' ? el.id === this.messageTo.groupId : el.id === this.messageTo.patientId);
      },
      set(val){
        this.user.role === 'patient' ? 
          this.setMessageTo({...this.messageTo, groupId: val}) : 
          this.setMessageTo({...this.messageTo, patientId: val});

        this.error = null;
      }
    },
    message: {
      get(){
        return this.messageTo.message;
      },
      set(val){
        this.setMessageTo({...this.messageTo, message: val});
        this.error = null;
      }
    },
  },
  methods: {
    ...messages.mapMethods(['pullMessageGroupsAsync', 'setMessageTo', 'createConversationAsync']),
    createConversation (){
      this.$emit('goToConversation');
      this.createConversationAsync().then(() => {
        this.subject = ""
        this.group = ""
        this.message = ""
      })
    },
    discard () {
      this.subject = ""
      this.message = ""
      this.group = null
    }
  },
  mounted () {
    this.pullMessageGroupsAsync()
  }
}
</script>

<style scoped>
.v-card{
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.v-toolbar-title{
  align-self: center;
}

.v-card-actions{
  align-self: bottom;
}

.scrollCol{
  overflow: auto;
}
</style>